<template>
  <div class="container ma-tb-20">
    <car-state
      ref="carState"
      :phone="detail.CallBox"
      show="true"
      :taskId="taskId"
      :isMap="isMap"
      :taskStates="detail.TaskInfo.Status"
    ></car-state>
    <div class="bg-fff setting-index">
      <back-button msg="任务详情"></back-button>
      <div class="flex f-between" v-if="!showMap">
        <div class="box bg-F7">
          <div class="box-title fon-18 font-bold">病人信息</div>
          <div class="fon-16 box-list">
            <div class="flex f-center ma-5">
              <div class="c-999">报警时间:</div>
              <div class="c-000">{{ detail.TaskInfo.AlarmTime }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">报警人:</div>
              <div class="c-000">
                {{ detail.TaskInfo.People }} - {{ detail.TaskInfo.Phone }}
              </div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">病人:</div>
              <div class="c-000">
                {{ detail.TaskInfo.PatientName }}({{
                  detail.TaskInfo.PatientGender
                }}) {{ detail.TaskInfo.PatientAge }}岁
              </div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">担架:</div>
              <div class="c-000">
                {{ detail.TaskInfo.IsStretcher ? "需要" : "不需要" }}
              </div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">事故地点:</div>
              <div class="c-000">{{ detail.TaskInfo.SceneAddress }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">起因/症状:</div>
              <div class="c-000">{{ detail.TaskInfo.Symptom }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">受伤部位:</div>
              <div class="c-000">{{ detail.TaskInfo.InjuredPart }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">当前状态:</div>
              <div class="c-000">{{ detail.TaskInfo.Status }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">过敏源:</div>
              <div class="c-000">{{ detail.TaskInfo.Allergen }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">病史:</div>
              <div class="c-000">{{ detail.TaskInfo.MedicalHistory }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">说明:</div>
              <div class="c-000">{{ detail.TaskInfo.Remark }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">急救站:</div>
              <div class="c-000">{{ detail.TaskInfo.StationName }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">急救医院:</div>
              <div class="c-000">{{ detail.TaskInfo.HospitalName }}</div>
            </div>
            <!-- v-if="!detail.TaskInfo.EmergencySusmit" -->
            <router-link
              :to="{ name: 'taskCaseAdd', query: { id: taskId } }"
              v-if="detail.TaskInfo.Status != '取消'"
            >
              <div class="btn-add c-fff fon-20 flex f-center f-jcenter ">
                填写电子病历
              </div>
            </router-link>
          </div>
        </div>
          <div class="box-log box bg-F7">
            <div class="box-title fon-20 font-bold ">现场医嘱</div>
            <div class="log-list" v-if="detail.MedicalAdvice.length > 0">
              <div
                class="ma-5"
                v-for="(item, index) in detail.MedicalAdvice"
                :key="index"
              >
                <div class="flex f-center f-between">
                  <div class="time fon-16">{{ item.InsertTime }}</div>
                  <div
                    class="btn-detele flex f-center f-jcenter"
                    @click="deleteMedicalAdvice(item.Id)"
                  >
                    删除
                  </div>
                </div>
                <div class="fon-16 font-bold c-666">{{ item.Content }}</div>
              </div>
            </div>
            <van-empty
              description="暂无医嘱记录"
               image-size="80"
              v-if="detail.MedicalAdvice.length == 0"
            />
            <div
              v-if="detail.TaskInfo.Status != '取消'"
              class="btn-add c-fff fon-20 flex f-center f-jcenter"
              @click="showPopup"
            >
              填写医嘱
            </div>
          </div>
         
        <div class="box flex flex-direction f-between">
          <div class="bg-F7 box-log">
            <div class="box-title fon-18 font-bold">系统日志</div>
            <div class="log-list" v-if="detail.LogList.length > 0">
              <div
                class="ma-5 c-666 fon-16"
                v-for="(item, index) in detail.LogList"
                :key="index"
              >
                {{ item.InsertTime }}&nbsp;&nbsp;{{ item.Content }}
              </div>
            </div>
            <van-empty image-size="80" description="暂无系统日志" v-else />
          </div>
          <div class="map-box">
            <div @click="toDetail" class="map-mask"></div>
            <div id="container" style="width: 100%; height: 190px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <van-overlay :show="show" @click="show = false" z-index="2000">
    <div class="popup bg-fff" @click.stop>
      <div class="flex f-between popup-head">
        <div class="fon-16">添加医嘱</div>
        <van-icon size="20" name="close" @click="show = false" />
      </div>
      <div class="popup-content">
        <div class="flex f-center ma-b-10">
          <div class="fon-16 ma-r-10">添加时间:</div>
          <input type="datetime-local" v-model="insertTime" />
        </div>
        <div class="flex ma-b-10">
          <div class="fon-16 ma-r-10">医嘱内容:</div>
          <textarea
			class="textarea"
            rows="6"
            placeholder="请填写医嘱内容"
            v-model="content"
          ></textarea>
		  
        </div>
		<div class="">
			<div
			  class="btn-voice fon-16 flex f-center f-jcenter"
			  @touchstart.native.prevent="startRecorder"
			  @touchend.native.prevent="stopRecorder"
			>
			  <!-- @touchend="stopRecorder" -->
			  {{ voiceTxt }}
			</div>
		</div>
         <div class="fon-12 c-666 tips">
           按住不放然后进行语音输入，系统自动识别成文字
           <!-- 点击进行语音输入，系统自动识别成文字 -->
         </div>

        <div
          class="btn-submit fon-16 c-fff flex f-center f-jcenter "
          @click="addMedicalAdvice"
        >
          保存
        </div>
      </div>
    </div>
  </van-overlay>
</template>
<script>
let _this;
import Recorder from "js-audio-recorder";
import lamejs from "lamejs";
import AMap from "AMap"; // 引入高德地图
const recorder = new Recorder({
  sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
  sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值
  numChannels: 1, // 声道，支持 1 或 2， 默认是1
});

export default {
  data() {
    return {
      show: false,
      isMap: false,
      taskId: "",
      detail: {
        TaskInfo: "",
        MedicalAdvice: [],
        Member: "",
        LogList: [],
        CallBox: "",
      },
      insertTime: "",
      content: "",
      voicePath: "",
      showMap: false,
      mapList: [],
      centerLon: 0,
      centerLat: 0,
      oncde: 0,
      map: "",
      loadDataIntervala: null,
      voiceTxt: "语音录入",
      marker3: "",
      marker4: "",
      PolylinePath: [],
      loadDataInterval:null
    };
  },
  created() {
    _this = this;
    _this.taskId = _this.$route.query.id;
    _this.loadData();
    _this.recorder = new Recorder();
    
  },
  mounted() {
    _this.$refs.carState.isShow = true;

    _this.init();
    _this.loadDataIntervala = setInterval(() => {
      _this.init();
    }, 1000 * 20);

    let time = localStorage.getItem('refresh_time')
    if (!_this.$validator.isEmpty(time) && time != 0) {
      console.log('detail')
      _this.loadDataInterval = setInterval(() => {
        _this.loadData();
      }, 1000 * time);
    }
  },

  //监听当前页面返回事件
  beforeRouteLeave(to, from, next) {
    clearInterval(_this.loadDataIntervala);
    clearInterval(_this.loadDataInterval)
    next();
  },
  methods: {
    toDetail() {
      _this.$router.push({
        name: "map",
        query: {
          id: _this.taskId,
        },
      });
    },

    async init() {
      //获取救护车的位置
      _this.mapList = await _this.$request(
        "/Ambulance/v1/Location/Get?taskId=" + _this.taskId,
        {},
        false,
        true
      );
      _this.mapList = _this.mapList.Data;
      if (_this.mapList.PolylinePath.length > 0) {
        _this.mapList.PolylinePath.forEach((e, v) => {
          _this.mapList.PolylinePath[v] = JSON.parse(e);
        });
        _this.centerLon =
          _this.mapList.PolylinePath[_this.mapList.PolylinePath.length - 1][0];
        _this.centerLat =
          _this.mapList.PolylinePath[_this.mapList.PolylinePath.length - 1][1];
      } else {
        _this.centerLon = _this.mapList.Hospital.Longitude;
        _this.centerLat = _this.mapList.Hospital.Latitude;
        clearInterval(_this.loadDataIntervala);
      }

      _this.getMap();
      _this.once = 1;
    },

    // 获取地图
    getMap() {
      if (!_this.once) {
        _this.map = new AMap.Map("container", {
          resizeEnable: true,
          expandZoomRange: true,
          center: [_this.centerLon, _this.centerLat], //地图中心点
          zoom: 16,
        });
      } 
      // 创建一个 Marker 实例：

      if (_this.mapList.PolylinePath.length > 0) {
        AMap.convertFrom(
          _this.mapList.PolylinePath,
          "gps",
          function (status, result) {
            if (result.info === "ok") {
              _this.map.setZoomAndCenter(_this.map.getZoom(), [
                result.locations[result.locations.length - 1].KL,
                result.locations[result.locations.length - 1].kT,
              ]);
               _this.PolylinePath = [];
              result.locations.forEach((v, e) => {
                _this.PolylinePath.push([v.KL, v.kT]);
              });
              _this.path = new AMap.Polyline({
                path: _this.PolylinePath,
                isOutline: false, //线条是否带描边，默认false
                outlineColor: "#ffeeff", //线条描边颜色，此项仅在isOutline为true时有效，默认：#000000
                borderWeight: 1, //描边的宽度，默认为1
                strokeColor: "#3366FF", //线条颜色，使用16进制颜色代码赋值。默认值为#006600
                strokeOpacity: 1, //线条透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
                strokeWeight: 3, //线条宽度，单位：像素
                // 折线样式还支持 'dashed'
                strokeStyle: "solid", //线样式，实线:solid，虚线:dashed
                // strokeStyle是dashed时有效
                // strokeDasharray: [10, 5],//勾勒形状轮廓的虚线和间隙的样式，此属性在strokeStyle 为dashed 时有效
                lineJoin: "round", //折线拐点的绘制样式，默认值为'miter'尖角，其他可选值：'round'圆角、'bevel'斜角
                lineCap: "round", //折线两端线帽的绘制样式，默认值为'butt'无头，其他可选值：'round'圆头、'square'方头
                zIndex: 50, //折线覆盖物的叠加顺序。默认叠加顺序，先添加的线在底层，后添加的线在上层。通过该属性可调整叠加顺序，使级别较高的折线覆盖物在上层显示默认zIndex：50、
              });
              // // 将折线添加至地图实例
              _this.map.add(_this.path);
            }
          }
        );
      } else {
        AMap.convertFrom(
          [_this.centerLon, _this.centerLat],
          "gps",
          function (status, result) {
            if (result.info === "ok") {
              _this.map.setZoomAndCenter(_this.map.getZoom(), [
                result.locations[0].KL,
                result.locations[0].kT,
              ]);
            }
          }
        );
      }
      //医院的位置
      //  _this.marker3 = new AMap.Marker({
      //   position: new AMap.LngLat(
      //     _this.mapList.Hospital.Longitude,
      //     _this.ma  ), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      //   title: _this.mapList.Hospital.Name,
      // });pList.Hospital.Latitude
      //

      //救护车的位置

      // _this.marker4 = new AMap.Marker({
      //     position: new AMap.LngLat(
      //       _this.mapList.PolylinePath[_this.mapList.PolylinePath.length - 1][0],
      //       _this.mapList.PolylinePath[_this.mapList.PolylinePath.length - 1][1]
      //     ), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      //     title: "救护车的位置",
      // icon:'',
      // icon: new AMap.Icon({
      //   image:
      //     "https://weiting-develop.oss-cn-shanghai.aliyuncs.com/Hospital/Image/390f1aa956b04f7e85f921429e1946d4_2021.png",
      //   size: new AMap.Size(28, 28), //图标所处区域大小
      //   imageSize: new AMap.Size(28, 28), //图标大小
      // }),
      // });
      // 将创建的点标记添加到已有的地图实例：
      // _this.map.add( _this.marker3);
      // _this.map.add( _this.marker4);
    },
    // 加载数据
    async loadData() {
      let _data = await _this.$request(
        "/Task/v1/TaskTask/Detail?taskId=" + _this.taskId,{},
        false,
        true
      );
      if (_data.Code == 0) {
        _this.detail = _data.Data;
      }
    },

    // 添加医嘱弹框
    showPopup() {
      _this.show = true;
      _this.content = "";
      let datetime = new Date();
      _this.insertTime = _this.$util.dateTime(datetime);
    },

    // 添加医嘱
    async addMedicalAdvice() {
      if (_this.$validator.isEmpty(_this.content)) {
        _this.$toast.fail({
          message: "请填写内容",
          duration: "800",
        });
        return;
      }
      let _data = await _this.$request("/Task/v1/TaskMedicalAdvice/Add", {
        method: "POST",
        data: {
          TaskId: _this.taskId,
          InsertTime: _this.insertTime,
          Content: _this.content,
          VoicePath: _this.voicePath,
        },
      });
      console.log(_data);
      if (_data.Code == 0) {
        _this.$toast.success({
          message: "添加成功",
          duration: "1000",
          onClose() {
            _this.show = false;
            _this.loadData();
          },
        });
      }
    },

    // 删除医嘱
    deleteMedicalAdvice(id) {
      _this.$dialog
        .confirm({
          title: "提示",
          message: "确认删除该医嘱吗？",
        })
        .then(async () => {
          let _data = await _this.$request(
            "/Task/v1/TaskMedicalAdvice/Delete",
            {
              method: "POST",
              data: {
                Id: id,
              },
            }
          );
          console.log(_data);
          if (_data.Code == 0) {
            _this.$toast.success({
              message: "删除成功",
              duration: "800",
              onClose() {
                _this.loadData();
              },
            });
          }
        });
    },

    // 开始录音
    startRecorder() {
      Recorder.getPermission().then(
        () => {
          recorder.start().then(
            () => {
              _this.$toast.loading({
                message: '录制中...',
                forbidClick: true,
                duration: 0
              });
              _this.isVoice = true;
            },
            (error) => {
              // 出错了
              return _this.$toast(`${error.name} : ${error.message}`);
            }
          );
        },
        (error) => {
          // alert(`${error.name} : ${error.message}`)
          return _this.$toast("请在设置中打开录音权限");
        }
      );
    },

    // 结束录音
    stopRecorder() {
      _this.$toast.clear();
      if (_this.isVoice) {
        recorder.stop();
        _this.getMp3Data();
      }
    },

    /**
     * 识别音频
     * */
    async getMp3Data() {
      _this.$toast.loading({
        message: '识别中...',
        forbidClick: true,
        duration: 0
      });
      let mp3Name = encodeURIComponent(
        "audio_recording_" + new Date().getTime() + ".mp3"
      );
      let formData = new FormData();
      let file = this.convertToMp3(recorder.getWAV());
      file = new File([file], mp3Name);
      formData.append("VoiceFile", file);
      formData.append("Token", localStorage.getItem("login.token"));
      formData.append("Source", "web");
      let _urlData = await _this.$request(
        "/Aliyun/v1/SpeechInteraction/VoiceToText",
        {
          method: "POST",
          data: formData,
        },
        true,
        true
      );
      _this.$toast.clear();
      _this.content = _urlData.Data.Text;
    },

    // 文件格式转换wav-map3
    convertToMp3(wavDataView) {
      // 获取wav头信息
      const wav = lamejs.WavHeader.readHeader(wavDataView); // 此处其实可以不用去读wav头信息，毕竟有对应的config配置
      const { channels, sampleRate } = wav;
      console.log("wav", wav);
      const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
      // 获取左右通道数据
      const result = recorder.getChannelData();
      const buffer = [];

      const leftData =
        result.left &&
        new Int16Array(result.left.buffer, 0, result.left.byteLength / 2);
      const rightData =
        result.right &&
        new Int16Array(result.right.buffer, 0, result.right.byteLength / 2);
      const remaining = leftData.length + (rightData ? rightData.length : 0);

      const maxSamples = 1152;
      for (let i = 0; i < remaining; i += maxSamples) {
        const left = leftData.subarray(i, i + maxSamples);
        let right = null;
        let mp3buf = null;
        if (channels === 2) {
          right = rightData.subarray(i, i + maxSamples);
          mp3buf = mp3enc.encodeBuffer(left, right);
        } else {
          mp3buf = mp3enc.encodeBuffer(left);
        }
        if (mp3buf.length > 0) {
          buffer.push(mp3buf);
        }
      }

      const enc = mp3enc.flush();

      if (enc.length > 0) {
        buffer.push(enc);
      }

      return new Blob(buffer, { type: "audio/mp3" });
    },
  },
};
</script>

<style lang="less" scoped>
.tips{
	margin-left:80px;
}
.title {
  margin-left: 0;
}
.ma-5 {
  margin: 5px 5px 5px 9px;
}
.bg-F7 {
  background: #f7f7f7;
}
.setting-index {
  height: auto;
  .box {
    min-height: 460px;
    width: 32%;
    border-radius: 4px;
    position: relative;
    margin-top: 8px;
    .c-999 {
      width: 38%;
    }
    .c-000 {
      width: 90%;
    }
    .box-list {
      height: 380px;
      overflow-y: scroll;
    }
    .box-title {
      color: #5cb9da;
      padding: 5px 9px;
      border-bottom: 1px solid #5ab8d9;
    }
    .btn-add {
      width: 100%;
      height: 48px;
      background: #5cb9da;
      border-radius: 4px;
      position: absolute;
      bottom: -1px;
    }
    .time {
      color: #a0a0a0;
    }
    .btn-detele {
      font-size: 15px;
      font-family: MicrosoftYaHei;
      color: #989696;
      line-height: 16px;
      background: #e6e6e6;
      width: 45px;
      height: 25px;
      border-radius: 5px;
    }
    .box-log {
      height: 250px;
      width: 100%;
      border-radius: 4px;
      position: relative;
      .log-list {
        height: 250px;
        overflow-y: scroll;
      }
    }
    .map-box {
      width: 100%;
      height: 190px;
      position: relative;
      .map-mask {
        width: 100%;
        height: 190px;
        position: absolute;
        z-index: 999;
      }
    }
  }
}
.popup {
  width: 520px;
  height: 450px;
  position: relative;
  top: 15%;
  left: 50%;
  transform: translate(-50%, 0);
  .popup-head {
    background: #f0f0f0;
    padding: 10px;
    color: #303039;
  }
  .popup-content {
	
    color: #303039;
	margin:20px 0 0 20px;
    input,
    textarea {
      font-size: 16px;
      border: none;
      background: transparent;
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(217, 217, 217, 1);
      border-radius: 4px;
      padding: 5px;
      width: 240px;
    }
    textarea {
      width: 360px;
      min-height: 90px;
    }
    .star {
      color: #eb2a26;
    }
    .btn-voice {
		margin-left:80px;
      border: 1px solid #5ab8d9;
      color: #5ab8d9;
      padding: 0px;
	  width:190px;
	  height: 40px;
      border-radius: 4px;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      -khtml-user-select: none;
    }
    .btn-submit {
      width: 180px;
	  margin:60px auto;
      height: 33px;
      background: #5cb9da;
      border-radius: 4px;
    }
  }
}
.flexs {
  display: flex !important;
  justify-content: space-between !important;
  flex-wrap: wrap;
}
</style>